import * as React from 'react'
import { Container,
    Heading,
    Stack,
    Box,
    Text } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import ApplicantInputs from '../../components/submitClaim/ApplicantInputs'
import usePrismicStandardDocumentApplication from "../../hooks/usePrismicStandardDocumentApplication";
import RichText from "../../components/PrismicRichText";

const SubmitClaimApplication = () => {
  const { language }= useTranslation()
    const data = usePrismicStandardDocumentApplication()
    return(
    <Main>
      <Container
        maxW="7xl"
        pb="6.25rem">
        <Seo
          title={data.header}
          description={data.header}
          link={`https://www.fsm.am/${language}/submit-claim-application`}
        />
        <SmallNav>
            {data.header}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{base:"2xl", sm:"3xl"}}
          fontWeight="900"
          mt="5"
          mb="3.75rem"
          >
            {data.header}
        </Heading>
        <Stack direction={{base:"column", md:"row"}} >
          <ApplicantInputs data={data} />
          <Box maxW={{base:"full",md:"324px"}} borderTop={{base:"1px", md:"0"}} pt={{base:"30px"}}>
            <Box fontSize="sm">
                <RichText text={data.description}/>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Main>
  )
}

export default SubmitClaimApplication

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`