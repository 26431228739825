import * as React from 'react';
import {
  HStack,
  Stack,
  Checkbox,
  Text,
  Box,
  Button,
  Flex
} from '@chakra-ui/react';
import { InputField } from '../../components/inputfield';
import { TextareaField } from '../../components/textareafield';
import Attach from '../../assets/attachment.svg';
import Download from '../../assets/download.svg';

const ApplicantInputs = ({ data }) => {
  return (
    <Box w="full" mb={{base:"30px"}}>
      <Text mb="6" >{data.applicant}</Text>

      <Stack spacing="4" maxW="42rem" w="full" mb="2.5rem">
        <Stack
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", md: "row" }}
        >
          <InputField label={data.name} />
          <InputField label={data.surname} />
        </Stack>
        <InputField label={data.address} />
        <Stack
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", md: "row" }}
        >
          <InputField label={data.email} />
          <InputField label={data.phone} />
        </Stack>
        <Stack
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", md: "row" }}
        >
          <InputField label={data.landLine} />
          <InputField label={data.workphone} />
        </Stack>
      </Stack>
      <Text mb="1rem">{data.authorizedPerson}</Text>
      <Checkbox
        colorScheme="green"
        mb="10">
        <Text fontSize="sm" color="grey.100">
          {data.authorizedPerson_1}
        </Text>
      </Checkbox>
      <Box maxW="42rem">
        <Text mb="4">{data.companyType}</Text>
        <Stack
          mb="10"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", md: "row" }}
        >
          <InputField label={data.nameC} />
          <InputField label={data.companyName} />
        </Stack>
        <Text mb="4">{data.propertyClaimSize}</Text>
        <Stack
          mb="10"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", md: "row" }}
        >
          <InputField label={data.size} />
          <InputField label={data.currency} />
        </Stack>
        <Text mb="4">{data.demandDescription}</Text>
        <TextareaField mb="2.5rem" />
        <Box as={Button} mx="auto" mb="10">
          <Attach h="24px" />
          <Text fontSize="sm" color="grey.100">
            {data.attachDemandDescription}
          </Text>
        </Box>
        <Text mb="4">{data.customerDemand}</Text>
        <TextareaField mb="2.5rem" />
        <Text mb="6">{data.declaration}</Text>
        <Stack w="full" mb="5rem">
          {data.declarationGroup?.map((declaration, idx) => (
              <Checkbox key={`declaration-${declaration}-${idx}`} display="flex" alignItems="flex-start" colorScheme="green">
                <Text fontSize="sm" color="grey.100">
                  {declaration}
                </Text>
              </Checkbox>
          ))}
        </Stack>
        <Text mb="2rem">{data.generate}</Text>
        <Button
          variant="darkGreen"
          w={{base: 'full', sm: "17rem"}}
          mb="6.75rem"
          leftIcon={<Download />}
        >
          {data.download}
        </Button>
        <Text mb="4">{data.listAttachedDocuments}</Text>
        <Stack maxW="full"  spacing={{base:"1rem",md:"5rem"}} direction={{base:"column", md:"row"}} >
          <HStack  minW="200px" spacing="2rem">
            <Box
              as={Button}
              bg="border.100"
              borderRadius="100px"
              w="44px"
              h="44px"
             >
              <Attach h="23px" />
            </Box>
            <Text fontSize="sm" color="grey.100">
              {data.fileCount}
            </Text>
          </HStack>
          <InputField />
        </Stack>
        <HStack spacing="2rem" mt="2.375rem" mb="4.5rem">
          <Button
             variant="darkGreen"
             w="44px"
             h="44px"
             borderRadius="100px"
             color="white"
             >
               +
          </Button>
          <Text fontSize="sm">{data.add}</Text>
        </HStack>
        <Flex
          h="4.5rem"
          w="17.31rem"
          border="1px solid"
          borderColor="border.100"
          alignItems="center"
          pl="3"
          mb="5"
          >
          <Checkbox colorScheme="green">{data.captcha}</Checkbox>
          
        </Flex>
        <Button w="full" variant="green">{data.confirm}</Button>
      </Box>
    </Box>
  );
};
export default ApplicantInputs;
