import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicStandardDocumentApplication = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicStandardDocumentApplication {
      allPrismicSubmitclaimapplication(
    filter: {tags: {in: "submit_claim_application"}}
  ) {
        nodes {
          lang
          data {
            add
            address
            applicant
            attachdemanddescription
            authorizedperson
            authorizedperson_1
            captcha
            companyname
            companytype
            confirm
            currency
            customerdemand
            declaration
            declarationgroup {
              declarationcheckbox
            }
            demanddescription
            description {
              richText
            }
            download
            downloadicon {
              url
            }
            email
            filecount
            generate
            header
            landline
            listattacheddocuments
            name
            namec
            phone
            propertyclaimsize
            size
            surname
            workphone
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicSubmitclaimapplication.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        header: response.header,
        add: response.add,
        address: response.address,
        applicant: response.applicant,
        attachDemandDescription: response.attachdemanddescription,
        authorizedPerson: response.authorizedperson,
        authorizedPerson_1: response.authorizedperson_1,
        captcha: response.captcha,
        companyType: response.companytype,
        companyName: response.companyname,
        confirm: response.confirm,
        currency: response.currency,
        customerDemand: response.customerdemand,
        declaration: response.declaration,
        demandDescription: response.demanddescription,
        description: response.description.richText,
        download: response.download,
        downloadIcon: response.downloadicon.url,
        email: response.email,
        fileCount: response.filecount,
        generate: response.generate,
        listAttachedDocuments: response.listattacheddocuments,
        name: response.name,
        nameC: response.namec,
        phone: response.phone,
        landLine: response.landline,
        propertyClaimSize: response.propertyclaimsize,
        size: response.size,
        surname: response.surname,
        workphone: response.workphone,
        declarationGroup: response.declarationgroup.map(item => item.declarationcheckbox),
    }
}

export default usePrismicStandardDocumentApplication;