import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import * as React from "react";

export const InputField = React.forwardRef((props, ref) => {
  const { label, labelProps = {}, ...rest } = props;

  return (
    <FormControl>
      <FormLabel fontSize="xs" mb="7.5px" w="full" {...labelProps}>
        {label}
      </FormLabel>
      <Input
        _focus={{ borderColor: "green.100" }}
        _hover={{ borderColor: "green.100" }}
        sx={{ boxShadow: "none !important" }}
        borderColor="border.100"
        borderWidth="1px"
        borderRadius="6px"
        ref={ref}
        height="43px"
        fontSize="md"
        {...rest}
      />
    </FormControl>
  );
});

InputField.displayName = "InputField";
